import {createApi} from "@reduxjs/toolkit/query/react";
import {BaseQueryInterceptor} from "./commonApi";
import {Banner, IGetBanners, IGetPromotions, IPromotion} from "../types/advertisementTypes";

export const advertisementApi = createApi({
    reducerPath: 'advertisementApi',
    baseQuery: BaseQueryInterceptor,
    endpoints: (build) => ({
        getAllPromotions: build.mutation<IPromotion[], IGetPromotions>({
            query: (obj) => ({
                url: `/promotions`,
                method: 'GET',
                params: obj,
            }),
        }),
        getAllBanners: build.mutation<Banner[], IGetBanners>({
            query: (obj) => ({
                url: `/banners`,
                method: 'GET',
                params: obj,
            }),
        }),
    })
});

export const {useGetAllPromotionsMutation, useGetAllBannersMutation} = advertisementApi