// noinspection AllyPlainJsInspection

import {ITheme} from "./ITheme";

const LettobetLightTheme: ITheme = {
    palette: {
        base: {
            main: '#C8102E',
            secondary: '#920D22',
            dop: '#C8102E',
            white: '#FFFFFF',
            black: '#000000',
        },
        color: {
            main: '#000000',
            secondary_1: '#000000',
            secondary_2: '#FFFFFF',
            secondary_3: '#000000',
            link: '#C8102E',
            placeholder: '#9B9EAD',
            active_1: '#C8102E',
            active_2: '#FFFFFF',
            disabled: '#A8A8A8',
        },
        background: {
            main_background: '#EBEBEB',
            main_image_path: '',
            main_content: '#FFFFFF',
            secondary_1: '#FFFFFF',
            secondary_2: '#FFFFFF',
            modal: '#FFFFFF',
            loader: '#D9D9D9',
            language: '#920D22',
            mobile_title: '#D9D9D9',
            active_1: '#C8102E',
            active_2: '#FFFFFF',
            disabled: '#252525',
        },
        border: {
            main: '#434550',
            secondary: '#ffffff',
            dop: '#464646',
            variant_1: "none",
        },
        shadow: {
            modal: '0 4px 4px ' + '#0000003F',
            footer: '0 4px 30px ' + '#0000003F',
            drawer: '0 4px 4px ' + '#0000003F',
            game_card: '0 4px 15px' + '#0000003F',
            content: '0 0 84px ' + '#0000004C',
            language: '0 4px 4px ' + '#0000003F',
            variant_1: '0 4px 4px ' + '#0000003F',
            text_1: '0 0 4px ' + '#0D141D',
        },
        svg: {
            main: '#000000',
            secondary: '#C8102E',
            dop: '#FFFFFF',
        },
        header: {
            desktop: {
                menu_color: '#000000',
                divide_color: '#FFFFFF',
                first_background: '#C8102E',
                second_background: '#FFFFFF',
                menu_hover_background: '#EBEBEB',
            },
            mobile: {
                background: '#C8102E'
            }
        },
        footer: {
            desktop: {
                behind: '#464646',
                front: '#464646',
            },
            mobile: {
                main: '#D9D9D9',
                color: '#5E5E5E',
                active: '#FFFFFF',
                active_color: '#5E5E5E',
                active_line: '#C8102E',
            }
        },
        button: {
            primary: {
                color: '#FFFFFF',
                border: '#C8102E',
                background: '#C8102E',
                hover_color: '#FFFFFF',
                hover_border: '#FFFFFF',
                hover_background: '#920D22',
                disabled_color: '#FFFFFF',
                disabled_border: '#920D22',
                disabled_background: '#920D22',
            },
            secondary: {
                color: '#C8102E',
                border: '#FFFFFF',
                background: '#FFFFFF',
                hover_color: '#C8102E',
                hover_border: '#920D22',
                hover_background: '#FFFFFF',
                disabled_color: '#C8102E',
                disabled_border: '#FFFFFF',
                disabled_background: '#FFFFFF',
            },
            simple: {
                color: '#000000',
                border: '#D9D9D9',
                background: '#D9D9D9',
                hover_color: '#535353',
                hover_border: '#434550',
                hover_background: '#D9D9D9',
                disabled_color: '#000000',
                disabled_border: '#D9D9D9',
                disabled_background: '#D9D9D9',
            },
        },
        game: {
            mobile_title: {
                color: '#000000',
                border: '#D9D9D9',
                background: '#D9D9D9',
                active_color: '#FFFFFF',
                active_border: '#C8102E',
                active_background: '#C8102E',
            },
            card: {
                title_background: '#FFFFFF',
                title_color: '#000000',
                favorite_color: '#000000',
                favorite_hover_color: '#C8102E',
                favorite_active_color: '#C8102E',
            },
            main_page: {
                tv_game_title_background_image_path: '',
                tv_game_title_background_color: '#FFFFFF',
                card_border: '#EBEBEB',
                link_color: '#000000',
            },
            other: {
                accordion_footer_gradient: 'linear-gradient(180deg, rgba(255,255,255,0.5) 0%, rgb(255,255,255) 40%, rgb(255, 255, 255) 100%)',
            }
        },
        mobile_profile_menu: {
            odd_color: '#000000',
            even_color: '#000000',
            svg_odd_color: '#C8102E',
            svg_even_color: '#C8102E',
            background: '#F6F6F6',
            cell_background: '#FFFFFF',
            cell_border: '2px solid ' + '#AEA9A9',
            title_svg_color: '#C8102E',
        },
        jackpot: {
            desktop_text: '#FFFFFF',
            desktop_numbers: '#FFFFFF',
            mobile_text: '#000000',
            mobile_numbers: '#C8102E',
        },
        theme_toggle: {
            border: '',
            background: '',
            toggle_background: '',
            icon_color: '',
        },
        date_picker: {
            color: '#000000',
            background: '#eaeaea',
            hover_background: '#b0b0b0',
            active_background: '#C8102E',
            arrow_icon_path: 'assets/images/desktop_menu_down_black_arrow_icon.svg',
        },
        hall_of_fame: {
            text_color: '#181818',
            secondary_text_color: '#79531F',
            third_text_color: '#906325',
            main_color: '#4D2608',
            secondary_color: '#181818',
            border_color: '#724E1D',
            background_color: '#A1712B',
            secondary_background_color: '#C89D4B',
            background_hover: '#FFF6B1',
            background: 'assets/images/hall_of_flame/bg_Def.png',
            background_image: 'assets/images/hall_of_flame/bg_Item_Gl.png',
            background_image_mobile: 'assets/images/hall_of_flame/bg_Item_Def_mobile.png',
            box_shadow: '0px 4px 15px rgba(0, 0, 0, 0.25)'
        },
        high_roller: {
            text_color: '#FFFFFF',
            secondary_text_color: '#F3EBA8',
            svg_color: '#F3EBA8',
            border_color: '#C8102E',
            background_color: "#920D22",
            secondary_background_color: '#920D22',
            background_image: 'assets/images/footer/high_roller_bg.svg',
            button_open: 'assets/images/footer/high_roller_arrow.svg',
            button_close: 'assets/images/footer/high_roller_close.svg',
        },
        success: {
            primary: '#03D771',
            secondary: '#27AE60',
        },
        error: {
            primary: '#C01414',
            secondary: '#C01414',
        },
        alert: {
            success: '#03D771',
            warning: '#F8B500',
            error: '#C01414',
        },
    },
    size: {
        games: {
            desktop: {
                column: 6,
                row: 3,
                height_img: 118,
                height_title: 32,
                width: 162,
                row_gap: 15,
                column_gap: 14,
            },
            mobile: {
                column: 3,
                row: 4,
                height_img: -1,
                height_title: 35,
                width: -1,
                row_gap: 7,
                column_gap: 7,
            }
        },
        tv_games: {
            desktop: {
                column: 4,
                row: 2,
                height_img: 160,
                height_title: 43,
                width: 245,
                row_gap: 15,
                column_gap: 22,
            },
            mobile: {
                column: 2,
                row: 3,
                height_img: 90,
                height_title: 40,
                width: -1,
                row_gap: 7,
                column_gap: 8,
            }
        }
    }

}

export default LettobetLightTheme