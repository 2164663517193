import {ISettings} from './interfaces/ISettings'

type languages = 'en' | 'fr'

const crazywin_settings: ISettings<languages> = {
    API_DEV_URL: 'https://api.crazywin365.com',
    API_PROD_URL: 'https://api.crazywin365.com',

    MIRRORS: {},

    OTHER: {
        CURRENCY: {
            TITLE: 'TND',
            KEY: 'TND'
        },
        LANGUAGES: {
            en: {
                VISIBLE: true,
                DEFAULT: false,
                DIRECTION: 'rtl',
                TITLE: 'English',
                KEY: 'en'
            },
            fr: {
                VISIBLE: true,
                DEFAULT: true,
                DIRECTION: 'rtl',
                TITLE: 'French',
                KEY: 'fr'
            },

        },
        VARIANT: 'crazywin',
        HEADER: ['HELP', 'SPORT', 'LIVE', 'VIRTUAL_GAMES', 'GAMES', 'TV_GAMES', 'EVOLUTION', 'TURBO_GAMES', 'FAVORITE_GAMES', 'PROMOTIONS',],
        LOGIN_LOGO: 'crazywin',
        FOOTER: {
            MENU: ['HOME', 'SPORT', 'LIVE', 'GAMES', 'TV_GAMES'],
            PAYMENTS: [],
        },
        MOBILE_MENU: {
            HOME: {
                VISIBLE: false,
                ROW: 1,
                COLUMN: 1,
                SPAN: 1
            },
            SPORT: {
                VISIBLE: true,
                ROW: 1,
                COLUMN: 1,
                SPAN: 1
            },
            LIVE: {
                VISIBLE: true,
                ROW: 1,
                COLUMN: 2,
                SPAN: 1
            },
            VIRTUAL_GAMES: {
                VISIBLE: true,
                ROW: 2,
                COLUMN: 3,
                SPAN: 1
            },
            GAMES: {
                VISIBLE: true,
                ROW: 2,
                COLUMN: 2,
                SPAN: 1
            },
            TV_GAMES: {
                VISIBLE: true,
                ROW: 2,
                COLUMN: 1,
                SPAN: 1
            },
            EVOLUTION_MOBILE: {
                VISIBLE: true,
                ROW: 4,
                COLUMN: 1,
                SPAN: 2
            },
            AMATIC: {
                VISIBLE: false,
                ROW: 1,
                COLUMN: 1,
                SPAN: 1
            },
            FAVORITE_GAMES: {
                VISIBLE: false,
                ROW: 1,
                COLUMN: 1,
                SPAN: 1
            },
            PROMOTIONS: {
                VISIBLE: true,
                ROW: 3,
                COLUMN: 3,
                SPAN: 1
            },
            HORSES: {
                VISIBLE: false,
                ROW: 1,
                COLUMN: 1,
                SPAN: 1
            },
            TURBO_GAMES: {
                VISIBLE: true,
                ROW: 1,
                COLUMN: 3,
                SPAN: 1
            },
            PRAGMATIC: {
                VISIBLE: true,
                ROW: 3,
                COLUMN: 1,
                SPAN: 2
            },
            HALL_OF_FAME: {
                VISIBLE: true,
                ROW: 4,
                COLUMN: 3,
                SPAN: 1
            }
        }
    },


    SEO: {
        TITLE: 'CrazyWin365.com',
        SITE_NAME: 'CrazyWin365',
        LOGO_AND_FAVICON: 'crazywin',
        DESCRIPTION: 'Play with CrazyWin365',
        KEYWORDS: ['CrazyWin', 'CrazyWin365', '365']
    },

    THEME: {
        VARIANT: 'crazywin',
        DEFAULT: 'dark',
        TOGGLE: false
    },

    ACCESS: {
        LOGIN: {
            VISIBLE: true,
            PERMISSIONS: {}
        },
        REGISTER: {
            VISIBLE: false,
            PERMISSIONS: {}
        },
        RESET_PASSWORD: {
            VISIBLE: false,
            PERMISSIONS: {}
        },
        RECOVERY_PASSWORD: {
            VISIBLE: false,
            PERMISSIONS: {}
        },
        CONFIRM_EMAIL: {
            VISIBLE: false,
            PERMISSIONS: {}
        },
        GAME: {
            VISIBLE: true,
            PERMISSIONS: {}
        },
        GAMES: {
            VISIBLE: true,
            PERMISSIONS: {}
        },
        TV_GAMES: {
            VISIBLE: true,
            PERMISSIONS: {}
        },
        HORSES: {
            VISIBLE: false,
            PERMISSIONS: {}
        },
        PROFILE: {
            VISIBLE: true,
            PERMISSIONS: {}
        },
        PROFILE_PERSONAL_DATA: {
            VISIBLE: true,
            PERMISSIONS: {
                ID: true,
                USERNAME: false,
                PHONE: false,
                EMAIL: false,
                NAME: false,
                SURNAME: false,
                ADDRESS: false,
                ID_CARD: false,
                BALANCE: true,
                CURRENCY: true,
                FIRST_NAME: false,
                SECOND_NAME: false,
                LAST_NAME: false,
                BIRTHDAY: false,
                GET_FULL_DATA: false,
                UPDATE_USER_INFO: false
            }
        },
        PROFILE_DEPOSIT: {
            VISIBLE: false,
            PERMISSIONS: {
                RUNPAY: false,
                VOUCHER: false,
                MAKSI_PAPARA: false,
                MAKSI_CRYPTO: false,
                MAKSI_HAYHAY: false,
                MAKSI_PAYFIX: false,
                MAKSI_CREDIT_CARD: false,
                MAKSI_PEP: false,
                MAKSI_BANK: false
            }
        },
        PROFILE_USER_DOCUMENT: {
            VISIBLE: false,
            PERMISSIONS: {}
        },
        PROFILE_WITHDRAWAL: {
            VISIBLE: false,
            PERMISSIONS: {
                RUNPAY: false,
                BANK: false,
                MAKSI_PAPARA: false,
                MAKSI_CRYPTO: false,
                MAKSI_HAYHAY: false,
                MAKSI_PAYFIX: false,
                MAKSI_PEP: false,
                MAKSI_BANK: false
            }
        },
        PROFILE_OPERATION_HISTORY: {
            VISIBLE: true,
            PERMISSIONS: {
                TYPE: true,
                DATE: true,
                AMOUNT: true,
                STATUS: true,
                ID: false,
                DECLAINE_REASON: false,
            }
        },
        PROFILE_BET_HISTORY: {
            VISIBLE: true,
            PERMISSIONS: {}
        },
        PROFILE_CASHBACK_HISTORY: {
            VISIBLE: true,
            PERMISSIONS: {}
        },
        PROFILE_SETTINGS: {
            VISIBLE: true,
            PERMISSIONS: {}
        },
        PROMOTIONS: {
            VISIBLE: true,
            PERMISSIONS: {}
        },
        PROMOTION: {
            VISIBLE: true,
            PERMISSIONS: {}
        },
        HALL_OF_FAME: {
            VISIBLE: true,
            PERMISSIONS: {},
        },
    },

    SERVICES_DEV: {
        BETBY: {
            OPERATOR_ID: '2136455562131546112',
            BRAND_ID: '2156370870464028672',
            EXTERNAL_API: 'https://external-api.invisiblesport.com/api/v1/external_api/',
            BETSLIP_URL: 'https://ui.invisiblesport.com/bt-renderer.min.js',
            THEME_NAME: 'default-table'
        },
        TAWK: {
            PROPERTY_ID: '1g36hg3rc',
            ID: '628253fbb0d10b6f3e726d55'
        },
        YM: {
            KEY: '89932680'
        }
    },
    SERVICES_PROD: {
        BETBY: {
            OPERATOR_ID: '2139066602350579712',
            BRAND_ID: '2166529057536217088',
            EXTERNAL_API: 'https://gw9yca5f.sptenv.com/api/v1/external_api/',
            BETSLIP_URL: 'https://goodwin365.sptpub.com/bt-renderer.min.js',
            THEME_NAME: 'goodwin-365'
        },
        TAWK: {
            PROPERTY_ID: '1g36hg3rc',
            ID: '628253fbb0d10b6f3e726d55'
        },
        YM: {
            KEY: '89932680'
        }
    }

}

export default crazywin_settings
