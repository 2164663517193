import React, {useRef} from 'react';
import Lottie, {LottieRefCurrentProps} from "lottie-react";
import styled from "styled-components";
import crazywinLottie from "../lotties/crazywin_main_loader_lottie.json";

const CrazywinMainLoader = () => {
    const lottieRef = useRef<LottieRefCurrentProps | null>(null)

    lottieRef.current?.setSpeed(1)

    return (
        <Wrapper>
            <WrapperLoader>
                <Lottie
                    lottieRef={lottieRef}
                    animationData={crazywinLottie}
                    loop={true}
                    autoplay={true}
                    style={{width: 500, height: 1000}}
                />
            </WrapperLoader>
        </Wrapper>
    );
};

export default CrazywinMainLoader;

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${({theme}) => theme.palette.background.loader};

`

const WrapperLoader = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`




