import { createSlice } from "@reduxjs/toolkit";
import { paymentApi } from "../api/paymentApi";
import { IPaymentState, IPaymentSystems } from "../types/paymentTypes";
import { OrderedMap } from "immutable";

const initialState: IPaymentState = {
  voucher: null,
  deposit: null,
  paymentSettings: null,
  paymentSystems: OrderedMap(),
};

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        paymentApi.endpoints.applyVoucher.matchFulfilled,
        (state, { payload }) => {
          // console.log('applyVoucher = ', payload)
          state.voucher = payload;
        }
      )
      .addMatcher(
        paymentApi.endpoints.createPayment.matchFulfilled,
        (state, { payload }) => {
          // console.log('createPayment = ', payload)
          state.deposit = payload;
        }
      )
      .addMatcher(
        paymentApi.endpoints.getPaymentSettings.matchFulfilled,
        (state, { payload }) => {
          // console.log('getPaymentSettings = ', payload)
          state.paymentSettings = payload;
        }
      )
      .addMatcher(
        paymentApi.endpoints.getPaymentSystems.matchFulfilled,
        (state, { payload }) => {
          // console.log('getPaymentSystems = ', payload)
          state.paymentSystems = getSystemsMap(payload);
        }
      );
  },
});


export default paymentSlice.reducer;

const getSystemsMap = (newSystems: IPaymentSystems[]) => {
  let newMap: any = OrderedMap();
  for (let i in newSystems) {
    let newSystem = newSystems[i];
    newMap = newMap.set(newSystem.id, newSystem);
  }
  return newMap;
};
