import React from 'react';
import styled from "styled-components";
import Lottie from "lottie-react";
import lettobetMainLoader from "../lotties/lettobet_main_loader_lottie.json";

const LettobetMainLoader = () => {
    return (
        <Wrapper>
            <WrapperLoader>
                <Lottie
                    animationData={lettobetMainLoader}
                    loop
                    autoplay
                    style={{width: 500, height: 1000}}
                />
            </WrapperLoader>
        </Wrapper>
    );
};

export default LettobetMainLoader;

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${({theme}) => theme.palette.base.main};

`

const WrapperLoader = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`




