import {ITheme} from "./ITheme";
import GoodwinDarkTheme from "./GoodwinDarkTheme";
import LettobetLightTheme from "./LettobetLightTheme";
import GoodwinLightTheme from "./GoodwinLightTheme";
import CrazywinDarkTheme from "./CrazywinDarkTheme";
import {ProjectSettings} from "config";

const theme = ProjectSettings.theme().VARIANT

const themes: {
    [key: string]: {
        light: ITheme
        dark: ITheme
        [key: string]: ITheme
    }
} = {
    'goodwin': {
        light: GoodwinLightTheme,
        dark: GoodwinDarkTheme,
    },
    'lettobet': {
        light: LettobetLightTheme,
        dark: LettobetLightTheme,
    },
    'crazywin': {
        light: CrazywinDarkTheme,
        dark: CrazywinDarkTheme,
    }
}

export const getTheme = (type: string): ITheme => {
    return themes[theme][type] ?? themes.goodwin.light
}
