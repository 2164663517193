import {createSlice} from "@reduxjs/toolkit";
import {IAdvertisementState} from "../types/advertisementTypes";
import {advertisementApi} from "../api/advertisementApi";

const initialState: IAdvertisementState = {
    promotions: [],
    promotion: null,
    banners: []
}

const advertisementSlice = createSlice({
    name: "advertisement",
    initialState,
    reducers: {
        getPromotion(state, {payload}) {
            state.promotion = payload
        }
    },
    extraReducers: (builder => {
        builder
            .addMatcher(
                advertisementApi.endpoints.getAllPromotions.matchFulfilled,
                (state, {payload}) => {
                    // console.log('promotions = ', payload)
                    state.promotions = payload
                })
            .addMatcher(
                advertisementApi.endpoints.getAllBanners.matchFulfilled,
                (state, {payload}) => {
                    // console.log('banners = ', payload)
                    state.banners = payload
                })

    })
})

export const {getPromotion} = advertisementSlice.actions

export default advertisementSlice.reducer

