import {createSlice} from "@reduxjs/toolkit";
import {IHighRollerState} from "../types/highRollerTypes";
import {highRollerApi} from "../api/highRollerApi";

const initialState: IHighRollerState = {
    list: []
};

const highRollerSlice = createSlice({
    name: "highRoller",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addMatcher(
                highRollerApi.endpoints.getHighRoller.matchFulfilled,
                (state, {payload}) => {
                    state.list = payload;
                }
            )
    },
});


export default highRollerSlice.reducer;
