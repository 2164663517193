import React, {useEffect, useState} from 'react';
import {ReactComponent as CloseIcon} from "assets/images/input/input_cross_icon.svg";
import useInterval from "hooks/useInterval";
import {useTypedDispatch, useTypedSelector} from 'hooks/useTypedRedux';
import styled, {useTheme} from 'styled-components';
import {updateAlert} from "redux/reducers/appSlice";


type CustomAlertProps = {
    isDesktop?: boolean,
}

const CustomAlert = (props: CustomAlertProps) => {
    const theme = useTheme()
    const [play, setPlay] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertProgress, setAlertProgress] = useState(0);
    const dispatch = useTypedDispatch();
    const {alert} = useTypedSelector((state => {
        return {
            alert: state.appSlice.alert,
        }
    }))

    const handleClearAlert = () => {
        dispatch(updateAlert({
            ...alert,
            isOpen: false,
        }))
    }

    // @ts-ignore
    const color = theme.palette.alert[alert.status];

    useEffect(() => {
        if (alert.isOpen) {
            setOpenAlert(true)
            setAlertProgress(0);
            setPlay(true);
        } else {
            handleClose();
        }

    }, [alert.isOpen]);


    useInterval(() => {
        if (alertProgress !== 100) {
            setAlertProgress(alertProgress + 2)
        } else {
            setPlay(false);
            handleClose();
        }
    }, play ? 100 : null)

    const handleClose = () => {
        handleClearAlert();
        setOpenAlert(false);
        setAlertProgress(0);
    }

    return (
        <>

            <Wrapper id={`custom-alert`} isDesktop={props.isDesktop}
                     onClick={() => handleClose()} open={!openAlert}
                     color={color}>
                <Background isDesktop={props.isDesktop} width={alertProgress}/>
                <InfoContainer isDesktop={props.isDesktop}>
                    <Text isDesktop={props.isDesktop}>
                        {`${alert.message}`}
                    </Text>
                    <CloseIcon style={{marginRight: '20px'}}/>
                </InfoContainer>
            </Wrapper>
        </>

    );
};

export default CustomAlert;

const Text = styled.div<{ isDesktop?: boolean }>`
  color: ${({theme}) => theme.palette.base.white};
  text-transform: uppercase;
  font-family: 'Roboto Condensed', serif;
  font-weight: 500;
  font-size: ${({isDesktop}) => !isDesktop ? 15 : 24}px;
  margin-left: 60px;
  margin-right: 10px;
  @media (max-width: 306px) {
    font-size: 12px;
  }
`

const Background = styled.div<{ width: number, isDesktop?: boolean }>`
  width: ${({isDesktop}) => !isDesktop ? 306 : 600}px;
  background-color: ${({theme}) => theme.palette.base.white};
  opacity: 0.2;
  position: absolute;
  top: 0;
  left: ${({isDesktop, width}) => !isDesktop ? -306 + width * 3.2 : -600 + width * 6.5}px;
  transition: left .6s linear;
  height: 100%;
`


const Wrapper = styled.div<{ open: boolean, isDesktop?: boolean, color: string }>`
  width: ${({isDesktop}) => !isDesktop ? 306 : 600}px;
  height: ${({isDesktop}) => !isDesktop ? '45px' : 'auto'};
  min-height: 36px;
  padding: 7px 0;
  cursor: pointer;
  background: ${({color}) => color};
  box-sizing: border-box;
  overflow: hidden;
  position: fixed;
  top: ${({isDesktop}) => !isDesktop ? 7 : 138}px;
  transition: right .4s linear;
  right: ${({open, isDesktop}) => !open ? !isDesktop ? 4 : -1 : !isDesktop ? -310 : -610}px;
  display: flex;
  flex-direction: column;
  z-index: 99999999;
  border-radius: 50px 0 0 50px;
  @media (max-width: 306px) {
  }
`


const InfoContainer = styled.div<{ isDesktop?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px;
  width: 100%;
  height: 100%;

  & > svg > path {
    stroke: ${({theme}) => theme.palette.base.white};
    width: ${({isDesktop}) => !isDesktop ? 16 : 30}px;
    height: ${({isDesktop}) => !isDesktop ? 16 : 30}px;
  }

  & > svg {
    width: ${({isDesktop}) => !isDesktop ? 16 : 30}px;
    height: ${({isDesktop}) => !isDesktop ? 16 : 30}px;
  }
`
