import ReactGa from 'react-ga'

const GoogleService = (id: string) => {
    console.log(`ga-tracking-${id}-initialize`);
    ReactGa.initialize(id);
    ReactGa.pageview(window.location.pathname + window.location.search);
    console.log(`ga-tracking-${id}-ready`);
}

export default GoogleService
