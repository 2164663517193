import {createSelector} from "@reduxjs/toolkit";
import {AppSelector} from "../store";
import {IUser} from "../types/userTypes";
import {IStaticPage} from "../types/appTypes";

const isMobileState: AppSelector<boolean> = (state) => state.appSlice.isMobile
const userState: AppSelector<IUser | null> = (state) => state.userSlice.user
const userPaymentTypeState: AppSelector<boolean | undefined> = (state) => state.userSlice.user?.isPaymentSystemUser
const userCurrencyState: AppSelector<string | undefined> = (state) => state.userSlice.user?.currency
const userIdState: AppSelector<number | undefined> = (state) => state.userSlice.user?.id
const userPhoneState: AppSelector<string> = (state) => state.userSlice.user?.phone ?? ''
const userBalanceState: AppSelector<number> = state => state.userSlice.user?.balance ?? 0
const pagesState: AppSelector<IStaticPage[]> = (state) => state.appSlice.pages
const themeTypeState: AppSelector<string> = (state) => state.appSlice.theme_type
const isLoadingState: AppSelector<boolean> = (state) => state.appSlice.isLoading

export const selectIsMobile = createSelector(isMobileState, isMobile => isMobile)
export const selectUser = createSelector(userState, user => user)
export const selectUserId = createSelector(userIdState, userId => userId)
export const selectUserCurrency = createSelector(userCurrencyState, userCurrency => userCurrency)
export const selectUserPaymentType = createSelector(userPaymentTypeState, userType => userType !== false)
export const selectUserPhone = createSelector(userPhoneState, phone => phone)
export const selectUserBalance = createSelector(userBalanceState, balance => balance)
export const selectPages = createSelector(pagesState, pages => pages)
export const selectThemeType = createSelector(themeTypeState, theme => theme)
export const selectIsLoading = createSelector(isLoadingState, loading => loading)
