// noinspection AllyPlainJsInspection

import {ITheme} from "./ITheme";


const GoodwinDarkTheme: ITheme = {
    palette: {
        base: {
            main: '#2269C2',
            secondary: '#76B0FA',
            dop: '#2269C2',
            white: '#FFFFFF',
            black: '#000000',
        },
        color: {
            main: '#FFFFFF',
            secondary_1: '#9B9EAD',
            secondary_2: '#9B9EAD',
            secondary_3: '#FFFFFF',
            link: '#2269C2',
            placeholder: '#9B9EAD',
            active_1: '#2269C2',
            active_2: '#FFFFFF',
            disabled: '#A8A8A8',
        },
        background: {
            main_background: '#181818',
            main_image_path: 'assets/images/goodwin_desktop_dark_background_icon.svg',
            main_content: '#252525',
            secondary_1: '#131313',
            secondary_2: '#181818',
            modal: '#252525',
            loader: '#252525',
            language: '#252525',
            mobile_title: '#323232',
            active_1: '#2269C2',
            active_2: '#2269C2',
            disabled: '#252525',
        },
        border: {
            main: '#76B0FA',
            secondary: '#76B0FA',
            dop: '#9B9EAD',
            variant_1: "none",
        },
        shadow: {
            modal: '0 4px 84px ' + '#65AAFF99',
            footer: '0 4px 30px ' + '#00000019',
            drawer: '0 4px 4px ' + '#65AAFF33',
            game_card: '0 4px 15px' + '#65AAFF33',
            content: '0 0 84px ' + '#65AAFF99',
            language: '0 4px 4px ' + '#0000003F',
            variant_1: '0 4px 4px ' + '#65AAFF43',
            text_1: '0 0 9px ' + '#0A2D91',
        },
        svg: {
            main: '#FFFFFF',
            secondary: '#2269C2',
            dop: '#9B9EAD',
        },
        header: {
            desktop: {
                menu_color: '#FFFFFF',
                divide_color: '#5E5E5E',
                first_background: '#262626',
                second_background: '#131313',
                menu_hover_background: '#151515',
            },
            mobile: {
                background: '#131313'
            }
        },
        footer: {
            desktop: {
                behind: '#181818',
                front: '#181818',
            },
            mobile: {
                main: '#131313',
                color: '#FFFFFF',
                active: '#2269C2',
                active_color: '#FFFFFF',
                active_line: '#76B0FA',
            }
        },
        button: {
            primary: {
                color: '#FFFFFF',
                background: '#2269C2',
                border: '#2269C2',
                hover_color: '#FFFFFF',
                hover_border: '#76B0FA',
                hover_background: '#2269C2',
                disabled_color: '#FFFFFF',
                disabled_border: '#76B0FA',
                disabled_background: '#2269C2',
            },
            secondary: {
                color: '#FFFFFF',
                background: '#2269C2',
                border: '#2269C2',
                hover_color: '#FFFFFF',
                hover_border: '#76B0FA',
                hover_background: '#2269C2',
                disabled_color: '#FFFFFF',
                disabled_border: '#76B0FA',
                disabled_background: '#2269C2',
            },
            simple: {
                color: '#FFFFFF',
                background: '#5E5E5E',
                border: '#76B0FA',
                hover_color: '#5E5E5E',
                hover_border: '#76B0FA',
                hover_background: '#76B0FA',
                disabled_color: '#5E5E5E',
                disabled_border: '#76B0FA',
                disabled_background: '#76B0FA',
            },
        },
        game: {
            mobile_title: {
                color: '#FFFFFF',
                border: '#76B0FA',
                background: '#181818',
                active_color: '#FFFFFF',
                active_border: '#76B0FA',
                active_background: '#2269C2',
            },
            card: {
                title_background: '#2269C2',
                title_color: '#FFFFFF',
                favorite_color: '#2269C2',
                favorite_hover_color: '#FCC520',
                favorite_active_color: '#FFFFFF'
            },
            main_page: {
                tv_game_title_background_image_path: 'assets/images/main/main_tv_games_dark_footer_icon.svg',
                tv_game_title_background_color: '#181818',
                card_border: '#76B0FA',
                link_color: '#076685E5',
            },
            other: {
                accordion_footer_gradient: 'linear-gradient(180deg, rgba(37,37,37,0.5) 0%, rgb(37,37,37) 40%, rgb(37, 37, 37) 100%)',
            }
        },
        mobile_profile_menu: {
            odd_color: '#FFFFFF',
            even_color: '#FFFFFF',
            svg_odd_color: '#499C23',
            svg_even_color: '#2269C2',
            background: '#181818',
            cell_background: '#252525',
            cell_border: '1px solid ' + '#76B0FA',
            title_svg_color: '#2269C2',
        },
        jackpot: {
            desktop_text: '#FFFFFF',
            mobile_text: '#FFFFFF',
            desktop_numbers: '#2269C2',
            mobile_numbers: '#2269C2',
        },
        theme_toggle: {
            border: '#323232',
            background: '#323232',
            toggle_background: '#131313',
            icon_color: '#FFFFFF',
        },
        date_picker: {
            color: '#000000',
            background: '#eaeaea',
            hover_background: '#b0b0b0',
            active_background: '#2269C2',
            arrow_icon_path: 'assets/images/desktop_menu_down_white_arrow_icon.svg',
        },
        hall_of_fame: {
            text_color: '#FFFFFF',
            secondary_text_color: '#79531F',
            third_text_color: '#906325',
            main_color: '#4D2608',
            background_color: '#A1712B',
            secondary_background_color: '#C89D4B',
            background_hover: '#FFF6B1',
            border_color: '#724E1D',
            secondary_color: '#181818',
            background: 'assets/images/hall_of_flame/bg_Gd.png',
            background_image: 'assets/images/hall_of_flame/bg_Item_Gd.png',
            background_image_mobile: 'assets/images/hall_of_flame/bg_Item_Gd_mobile.png',
            box_shadow: '0px 4px 15px rgba(0, 0, 0, 0.25)'
        },
        high_roller: {
            text_color: '#76B0FA',
            secondary_text_color: '#F3EBA8',
            svg_color: '#434550',
            border_color: '#2F88F8',
            background_color: "#000000",
            secondary_background_color: '#060912',
            background_image: 'assets/images/footer/high_roller_bg.svg',
            button_open: 'assets/images/footer/high_roller_arrow.svg',
            button_close: 'assets/images/footer/high_roller_close.svg',
        },
        success: {
            primary: '#03D771',
            secondary: '#27AE60',
        },
        error: {
            primary: '#C01414',
            secondary: '#C01414',
        },
        alert: {
            success: '#03D771',
            warning: '#F8B500',
            error: '#C01414',
        },
    },
    size: {
        games: {
            desktop: {
                column: 6,
                row: 3,
                height_img: 118,
                height_title: 32,
                width: 162,
                row_gap: 15,
                column_gap: 14,
            },
            mobile: {
                column: 3,
                row: 4,
                height_img: -1,
                height_title: 35,
                width: -1,
                row_gap: 7,
                column_gap: 7,
            }
        },
        tv_games: {
            desktop: {
                column: 4,
                row: 2,
                height_img: 160,
                height_title: 43,
                width: 245,
                row_gap: 15,
                column_gap: 22,
            },
            mobile: {
                column: 2,
                row: 3,
                height_img: 90,
                height_title: 40,
                width: -1,
                row_gap: 7,
                column_gap: 8,
            }
        }
    }
}

export default GoodwinDarkTheme