import {
    BaseQueryFn,
    createApi,
    FetchArgs,
    fetchBaseQuery,
    retry,
} from "@reduxjs/toolkit/query/react";
import {resetAdmin} from "../reducers/userSlice";
import {ProjectSettings} from "config";

export interface CustomError {
    data: {
        message: string;
        error?: boolean;
        field?: string;
        code?: number;
        errors?: {
            location: string;
            msg: string;
            param: string;
            value: any;
            code: number;
        }[];
    };
    status: number;
}

interface CustomFetchArgs extends RequestInit {
    url: string;
    params?: Record<string, any>;
    body?: any;
    responseHandler?:
        | "json"
        | "text"
        | ((response: Response) => Promise<any>)
        | string;
    validateStatus?: (response: Response, body: any) => boolean;
}

const SETTINGS = ProjectSettings.settings();
const mirror = Object.keys(SETTINGS.MIRRORS).find(
    (item) => item === window.location.host
);

export const BASE_URL =
    ProjectSettings.node_env() === "development"
        ? SETTINGS.API_DEV_URL
        : mirror
            ? SETTINGS.MIRRORS[`${mirror}`].API_PROD_URL
            : SETTINGS.API_PROD_URL;


export const BaseQuery = fetchBaseQuery({
    baseUrl: BASE_URL + '/api',
    prepareHeaders: headers => {
        headers.set('Content-Type', 'application/json');
        return headers;
    },
    credentials: "include",
})


export const BaseQueryInterceptor = retry(
    async (args: string | FetchArgs, api, extraOptions) => {

        const result = await BaseQuery(args, api, extraOptions);

        if (
            result.error?.data === "deleted" ||
            result.error?.data === "OK" ||
            result.error?.data === "Password recovered"
        )
            return {data: result.error.data};

        if (result.error?.status === 401) {
            await fetchBaseQuery({baseUrl: `${BASE_URL}`})(
                {url: "api/logout", method: "DELETE"},
                api,
                extraOptions
            );
            api.dispatch(resetAdmin());
        }
        return result;
    },
    {maxRetries: 0}
) as BaseQueryFn<string | CustomFetchArgs, unknown, CustomError>;

export const commonAPI = createApi({
    reducerPath: "API",
    baseQuery: BaseQueryInterceptor,
    endpoints: () => ({}),
});
