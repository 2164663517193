import {BaseQueryInterceptor, commonAPI} from './commonApi';
import {IAppState, IJackpot, ILanguage, IStaticPage} from "../types/appTypes";
import {createApi} from "@reduxjs/toolkit/query/react";

export const appAPI = createApi({
        reducerPath: 'appApi',
        baseQuery: BaseQueryInterceptor,
        endpoints: (build) => ({
            getStaticPage: build.mutation<IStaticPage, number>({
                query: (id) => ({
                    url: `/pages/client/${id}`,
                    method: 'GET',
                }),
            }),
            getStaticPages: build.query<IStaticPage[], void>({
                query: () => ({
                    url: `/pages/client`,
                    method: 'GET',
                }),
            }),
            getJackpot: build.mutation<IJackpot, void>({
                query: () => ({
                    url: `/info/jackpot`,
                    method: 'GET',
                })
            }),
            getLanguages: build.query<ILanguage[], void>({
                query: () => ({
                    url: `/languages`,
                    method: 'GET',
                })
            })
        })
    })
;

export const {useGetStaticPageMutation, useGetStaticPagesQuery, useGetJackpotMutation, useGetLanguagesQuery} = appAPI