import React from 'react';
import styled from "styled-components";
import {ProjectSettings} from "config";
import {selectIsLoading} from "redux/selectors/appSelector";
import {useTypedSelector} from "hooks/useTypedRedux";
import LettobetMainLoader from "assets/loaders/LettobetMainLoader";
import CrazywinMainLoader from "assets/loaders/CrazywinMainLoader";
import {ReactComponent as GoodwinMainLoaderIcon} from "assets/images/animated/goodwin_main_loader.svg"

const Loader = (): JSX.Element | null => {

    const seo = ProjectSettings.seo()

    const isLoading = useTypedSelector(selectIsLoading)

    if (isLoading) {
        return (
            <LoaderWrapper>
                {/* @ts-ignore */}
                {seo.LOGO_AND_FAVICON === 'crazywin'
                    ? <CrazywinMainLoader/>
                    : seo.LOGO_AND_FAVICON === 'lettobet'
                        ? <LettobetMainLoader/>
                        : <GoodwinMainLoaderIcon/>
                }
            </LoaderWrapper>
        );
    } else {
        return null
    }
};

export default Loader;

const LoaderWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  z-index: 10000000;
  background-color: ${({theme}) => theme.palette.background.loader};
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;
`