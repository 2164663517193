import {combineReducers, configureStore} from "@reduxjs/toolkit"
import userSlice from "./reducers/userSlice"
import appSlice from "./reducers/appSlice";
import gamesSlice from "./reducers/gamesSlice";
import transactionSlice from "./reducers/transactionSlice";
import {userAPI} from "./api/userApi";
import {transactionAPI} from "./api/transactionApi";
import {gamesAPI} from "./api/gamesApi";
import {appAPI} from "./api/appApi";
import paymentSlice from "./reducers/paymentSlice";
import {paymentApi} from "./api/paymentApi";
import advertisementSlice from "./reducers/advertisementSlice";
import {advertisementApi} from "./api/advertisementApi";

import RtkCommonMiddleware from "./middlewares";
import hallOfFameSlice from "./reducers/hallOfFameSlice";
import {hallOfFameApi} from "./api/hallOfFameApi";
import {highRollerApi} from "./api/highRollerApi";
import highRollerSlice from "./reducers/highRollerSlice";

const rootReducer = combineReducers({
    userSlice,
    appSlice,
    gamesSlice,
    transactionSlice,
    paymentSlice,
    advertisementSlice,
    hallOfFameSlice,
    highRollerSlice,
    [userAPI.reducerPath]: userAPI.reducer,
    [transactionAPI.reducerPath]: transactionAPI.reducer,
    [gamesAPI.reducerPath]: gamesAPI.reducer,
    [appAPI.reducerPath]: appAPI.reducer,
    [paymentApi.reducerPath]: paymentApi.reducer,
    [advertisementApi.reducerPath]: advertisementApi.reducer,
    [hallOfFameApi.reducerPath]: hallOfFameApi.reducer,
    [highRollerApi.reducerPath]: highRollerApi.reducer,
})

export let store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        serializableCheck: false
    }).concat(userAPI.middleware,
        transactionAPI.middleware,
        gamesAPI.middleware,
        appAPI.middleware,
        paymentApi.middleware,
        advertisementApi.middleware,
        hallOfFameApi.middleware,
        highRollerApi.middleware,
        RtkCommonMiddleware,
    ),
})

export type RootState = ReturnType<typeof rootReducer>

export type AppDispatch = typeof store.dispatch

export type AppSelector<Return> = (state: RootState) => Return
export const createAppSelector = <R>(selector: AppSelector<R>): AppSelector<R> => selector